import { transformImageUrl } from '@kontent-ai/delivery-sdk';

const loader = (props) =>
    transformImageUrl(props.src)
        .withQuality(75)
        .withWidth(props.width)
        .withAutomaticFormat('webp')
        .withCompression('lossless')
        .getUrl();

export default loader;
