import NextImage, { getImageProps } from 'next/image';
import { Caption } from '@/components/caption';
import { breakpoints } from '@/assets/data/breakpoints';
import classNames from 'classnames';

export const Image = ({ image, ...props }) => {
    const artDirection = !!image.tablet.src || !!image.mobile.src;
    return (
        <ImageWrapper image={image} {...props}>
            {artDirection ? (
                <ArtDirectionImage image={image} {...props} />
            ) : (
                <DefaultImage image={image} {...props} />
            )}
        </ImageWrapper>
    );
};

const ImageWrapper = ({
    image,
    disableCaption,
    disableElevation,
    wrapperClassName,
    children,
}) => (
    <>
        <div
            className={classNames('image', {
                [`${wrapperClassName}`]: wrapperClassName,
                [`image--${image.elevation}`]:
                    image.elevation && !disableElevation,
                ['image--vertical']:
                    image?.center_alignment?.includes('vertical'),
                ['image--horizontal']:
                    image?.center_alignment?.includes('horizontal'),
            })}
            data-kontent-item-id={image.id}
            style={
                image?.max_width ? { maxWidth: `${image?.max_width}px` } : {}
            }
        >
            {children}
        </div>
        {image?.caption && !disableCaption && (
            <Caption
                caption={image.caption}
                alignment={image.caption_alignment}
                size={image.caption_size}
            />
        )}
    </>
);

const DefaultImage = ({
    image,
    className,
    defaultBackground,
    fill,
    objectPosition,
    objectFit,
    sizes,
    priority = false,
}) => (
    <NextImage
        className={classNames({
            [`${className}`]: className,
            [`bg-${image.bg_color || defaultBackground}`]:
                image.bg_color || defaultBackground,
        })}
        sizes={sizes}
        src={image.desktop.src}
        priority={priority}
        data-kontent-element-codename={image.codename}
        {...(fill
            ? {
                  fill,
                  style: {
                      objectFit: image.object_fit || objectFit,
                      objectPosition: image.object_position || objectPosition,
                  },
              }
            : {
                  width: image.desktop.width,
                  height: image.desktop.height,
                  style: {
                      objectFit: image.object_fit || objectFit,
                      objectPosition: image.object_position || objectPosition,
                      width: !image.native ? '100%' : null,
                      maxHeight: image.is_svg ? image.desktop.height : null,
                  },
              })}
        alt={image.alt}
    />
);

const ArtDirectionImage = ({
    image,
    className,
    defaultBackground,
    objectFit,
    objectPosition,
    fill,
    sizes,
    priority = false,
}) => {
    const common = { alt: image.alt, sizes };

    const desktop = image.desktop.src
        ? getImageProps({
              ...common,
              width: image.desktop.width,
              height: image.desktop.height,
              src: image.desktop.src,
          }).props
        : null;

    const tablet = image.tablet.src
        ? getImageProps({
              ...common,
              width: image.tablet.width,
              height: image.tablet.height,
              src: image.tablet.src,
          }).props
        : null;

    const mobile = image.mobile.src
        ? getImageProps({
              ...common,
              width: image.mobile.width,
              height: image.mobile.height,
              src: image.mobile.src,
          }).props
        : null;

    return (
        <picture>
            {desktop && (
                <source
                    media={`(min-width: ${breakpoints.desktop}px)`}
                    srcSet={desktop.srcSet || desktop.src}
                />
            )}
            {tablet && (
                <source
                    media={`(min-width: ${breakpoints.tablet}px)`}
                    srcSet={tablet.srcSet || tablet.src}
                />
            )}
            {mobile && (
                <source
                    media="(max-width: 768px)"
                    srcSet={mobile.srcSet || mobile.src}
                />
            )}
            <NextImage
                {...common}
                className={classNames({
                    [`${className}`]: className,
                    [`bg-${image.bg_color || defaultBackground}`]:
                        image.bg_color || defaultBackground,
                })}
                src={image.desktop.src}
                priority={priority}
                data-kontent-element-codename={image.codename}
                {...(fill
                    ? {
                          fill,
                          style: {
                              objectFit: image.object_fit || objectFit,
                              objectPosition:
                                  image.object_position || objectPosition,
                          },
                      }
                    : {
                          width: image.desktop.width,
                          height: image.desktop.height,
                          style: {
                              objectFit: image.object_fit || objectFit,
                              objectPosition:
                                  image.object_position || objectPosition,
                              width: !image.native ? '100%' : null,
                              maxHeight: image.is_svg
                                  ? image.desktop.height
                                  : null,
                          },
                      })}
            />
        </picture>
    );
};
